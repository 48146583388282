<template>
  <div>
    <v-row align="center" class="mx-0">
      <div style="padding: 8px">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab
                   :dark="!disabled"
                   color="primary"
                   small
                   v-on="on"
                   @click="acaoModal(true,'APROV')"
                   :disabled="disabled">
              <v-icon>thumb_up</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.aprovar') }}</span>
        </v-tooltip>
      </div>
      <div style="padding: 8px">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0"
                   fab
                   :dark="!disabled"
                   color="primary"
                   small
                   v-on="on"
                   @click="acaoModal(true,'EM_ANALISE')"
                   :disabled="disabled">
              <v-icon>announcement</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.enviar_analise') }}</span>
        </v-tooltip>
      </div>
      <div style="padding: 8px">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0"
                   fab
                   :dark="!disabled"
                   color="primary"
                   small
                   v-on="on"
                   @click="acaoModal(true,'REPROV')"
                   :disabled="disabled">
              <v-icon>thumb_down</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.reprovar') }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <v-dialog v-model="showModal" max-width="800">
      <v-card>
        <v-card-title>
        <span class="bar" style="text-transform: uppercase">
          {{
            aprovar ? $tc('label.aprovar') : reprovar ? $tc('label.reprovar') : $tc('label.enviar_analise')
          }}
        </span>
        </v-card-title>
        <br>
        <v-card-subtitle>
          {{ $tc('title.confirm_acao_aprov_mass') }}
          {{
            aprovar ? $tc('label.aprovar') : reprovar ? $tc('label.reprovar') : $tc('label.enviar_analise')
          }}
          {{ $tc('title.confirm_aprov_mass_pag_selec') }}
        </v-card-subtitle>
        <v-card-subtitle>
          {{ $tc('title.confirm_aprov_mass_irrev') }}
        </v-card-subtitle>
        <v-card-subtitle>
          {{ $tc('title.aprov_mass_pag_status_div') }}
          {{ existePagStatusDiverg ? exibePagStatusDiverg : $t('message.nenhum_pag_selecionado') }}
        </v-card-subtitle>
        <v-card-text>
          <v-textarea
            v-if="!aprovar"
            id="justificativa"
            name="justificativa"
            :label="`${$tc('label.justificativa', 1)}`"
            v-model="justificativa"
            auto-grow
            autofocus
            :rules="[rules.required]"
            rows="1"
          ></v-textarea>
          <v-textarea
            v-else
            id="justificativa"
            name="justificativa"
            :label="`${$tc('label.justificativa', 1)}`"
            v-model="justificativa"
            auto-grow
            autofocus
            rows="1"
          ></v-textarea>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="cabecalho"
            :items="buscaPagStatusCorreto">
            <template v-slot:item.cod_planejamento="{ item }">
              {{ item.idAcao }}
            </template>
            <template v-slot:item.cod_pagamento="{ item }">
              {{ item.id }}
            </template>
            <template v-slot:item.foco_acao="{ item }">
              {{ item.focoAcao }}
            </template>
            <template v-slot:item.tipo_de_verba="{ item }">
              {{ item.tipoVerba }}
            </template>
            <template v-slot:item.valor_pagamento="{ item }">
              {{ getMoney(item.valor) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="acaoModal(false,null)">{{ $t('label.nao') }}</v-btn>
          <v-btn color="primary" v-if="aprovar"  @click="aprovarPag(justificativa)">{{ $t('label.sim') }}</v-btn>
          <v-btn color="primary" v-if="emAnalise && justificativa" @click="enviarAnalisePag(justificativa)">{{ $t('label.sim') }}</v-btn>
          <v-btn color="primary" v-if="reprovar && justificativa"  @click="reprovarPag(justificativa)">{{ $t('label.sim') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getMoney } from '@/produto/common/functions/helpers';

export default {
  name: 'PagamentoListaUpload',
  components: {},
  props: {
    pagamentos: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buscaPagStatusCorreto() {
      return this.pagamentos.filter((el) => el.status === 'AGUARDANDO_APROVACAO');
    },
    existePagStatusDiverg() {
      return this.pagamentos.filter((el) => el.status === 'PENDENTE' || el.status === 'EM_ANALISE').length > 0;
    },
    exibePagStatusDiverg() {
      return JSON.stringify(this.pagamentos.filter((el) => el.status === 'PENDENTE' || el.status === 'EM_ANALISE').map((p) => p.id));
    },
  },
  data() {
    return {
      workflowPagamentoAcaoResource: this.$api.workflowPagamentoAcao(this.$resource),
      aprovar: false,
      reprovar: false,
      emAnalise: false,
      showModal: false,
      justificativa: null,
      cabecalho: [
        {
          text: this.$tc('label.cod_planejamento', 1),
          value: 'cod_planejamento',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$tc('label.cod_pagamento', 1),
          value: 'cod_pagamento',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$tc('label.foco_acao', 1),
          value: 'foco_acao',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$tc('label.tipo_de_verba', 1),
          value: 'tipo_de_verba',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$tc('label.valor_pagamento', 1),
          value: 'valor_pagamento',
          sortable: true,
          align: 'center',
        },
      ],
      pagination: {
        itemsPerPage: 10,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    getMoney,
    acaoModal(boolean, tipo) {
      this.showModal = boolean;
      this.limpaVariaveis();
      switch (tipo) {
        case 'APROV':
          this.aprovar = true;
          break;
        case 'EM_ANALISE':
          this.emAnalise = true;
          break;
        case 'REPROV':
          this.reprovar = true;
          break;
        default:
          return 'N/A';
      }
      return this.showModal;
    },
    limpaVariaveis() {
      this.aprovar = false;
      this.emAnalise = false;
      this.reprovar = false;
      this.justificativa = null;
    },
    aprovarPag(justificativa) {
      if (this.buscaPagStatusCorreto.length === 0) {
        this.$toast(this.$t('message.nenhum_pag_selecionado_aguard_aprov'));
        this.showModal = false;
        return;
      }
      Promise.all(this.buscaPagStatusCorreto.map((c) => {
        const idPagamento = c.id;
        return this.workflowPagamentoAcaoResource.aprovarPasso({ idPagamento },
          { observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.pag_aprov_mass_sucesso'));
        this.$emit('AprovacaoMassiva_sucesso');
        this.$emit('AprovacaoMassiva_limpaSelecionados');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
    reprovarPag(justificativa) {
      if (this.buscaPagStatusCorreto.length === 0) {
        this.$toast(this.$t('message.nenhum_pag_selecionado_aguard_aprov'));
        this.showModal = false;
        return;
      }
      Promise.all(this.buscaPagStatusCorreto.map((c) => {
        const idPagamento = c.id;
        return this.workflowPagamentoAcaoResource.reprovar({ idPagamento },
          { observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.pag_reprov_mass_sucesso'));
        this.$emit('AprovacaoMassiva_sucesso');
        this.$emit('AprovacaoMassiva_limpaSelecionados');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
    enviarAnalisePag(justificativa) {
      if (this.buscaPagStatusCorreto.length === 0) {
        this.$toast(this.$t('message.nenhum_pag_selecionado_aguard_aprov'));
        this.showModal = false;
        return;
      }
      Promise.all(this.buscaPagStatusCorreto.map((c) => {
        const idPagamento = c.id;
        return this.workflowPagamentoAcaoResource.enviarAnalise({ idPagamento },
          { observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.pag_em_analise_mass_sucesso'));
        this.$emit('AprovacaoMassiva_sucesso');
        this.$emit('AprovacaoMassiva_limpaSelecionados');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
  },
};
</script>
